import { useUiStore } from '~/store/ui';
import { Locales } from '#gql/default';
export default function useGenerateGQLQueryVars() {

  const uiStore = useUiStore();
  const router = useRouter();

  const isEditOrPreviewMode = () => {
    const route = useRoute();
    return route.query.epieditmode === 'false' || route.query.epieditmode === 'true';
  }

  const extractParams = (token: string | undefined, urlPath: string, language: string) => {
    let payload: any = {};

    if (token) {
      let base64String = token.split('.')[1];

      // Check if Buffer is defined (Node.js environment)
      if (typeof Buffer !== 'undefined') {
        // Node.js environment
        payload = JSON.parse(Buffer.from(base64String, 'base64').toString());
      } else {
        // Browser environment
        payload = JSON.parse(atob(base64String));
      }
    }
    
    let relativePath = (urlPath.length > 1 && urlPath != "/search") ? urlPath : null

    if (relativePath){
      const epiContentPrefix = "/EPiServer/CMS/Content/";
      if (relativePath.startsWith(epiContentPrefix)) {
          relativePath = relativePath.substring(epiContentPrefix.length - 1);
      }

      if (relativePath.endsWith('/')) {
          relativePath = relativePath.slice(0, -1)
      }

      if (relativePath.includes(",")) {
          relativePath = relativePath.substring(0, relativePath?.indexOf(','));
      }

      if (relativePath.endsWith('/')) {
          relativePath = relativePath.slice(0, -1)
      }
    }

    const routeSegment = relativePath?.split('/').slice(-1)[0];
    const contentId = payload.c_id && parseInt(payload.c_id!.toString());
    const workId = payload.c_ver && parseInt(payload.c_ver!.toString());

    let localeString = language.charAt(0).toUpperCase() + language.slice(1);
    if (localeString?.indexOf("-") !== -1){
      let split = localeString.split("-");
      localeString = split[0] + '_' + split[1].toUpperCase();
    }

    let locale : Locales | undefined = Locales[localeString as keyof typeof Locales];

    //Slovak is not supported by Optimizely Graph
    if(locale === undefined || locale === Locales.Sk_SK){
      locale = Locales.ALL;
    }

    return { relativePath, routeSegment, locale: locale, language, contentId: contentId, workId: workId }
  }

  const generateGQLQueryVars = (token: string | undefined, pathname: string, contentLanguage: string, startPageId: number): any => {
    const { relativePath, routeSegment, locale, language, contentId, workId } = extractParams(token, pathname, contentLanguage);
    let variables: any = {
      relativePath,
      routeSegment,
      locale: locale as Locales,
      language,
      statusEqual: "Published"
    };
    
    if (isEditOrPreviewMode() && token) {
        variables = workId === undefined 
                    ? { contentId, isCommonDraft: true, locale: locale as Locales, language } 
                    : { contentId, workId, locale: locale as Locales, language };
    } else if(!relativePath){
      //start page cannot be fetched by path, use contentId instead
      variables = { contentId: startPageId, locale: locale as Locales, language, statusEqual: "Published" };
    }

    return variables;
  }

  return {
    generateGQLQueryVars,
  };
}
